/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";


*{
    font-family:"regular";
    color:#58595B;
    --ripple-color: transparent;
    outline: none;
    font-size: 16px;
}

ion-header {
    box-shadow: 0px 3px 20px rgb(0 0 0 / 9%);
}

.d-flex{display: flex;align-items: center;}
.ml-auto{margin-left: auto !important;}
.mr-auto{margin-right: auto !important;}
.m-0{margin: 0;}
.p-0{padding: 0;}
.text-center{text-align: center;}
.p-r-l{padding: 0 15px;}

h1,h2,h3,h4,h5,h6{
    font-family: "bold";
}

ion-item{    
    --background-activated: transparent;
}

.header-md::after{
    display: none;
}

ion-footer::after,ion-footer::before{ 
    display: none;
}

ion-footer {
    box-shadow: 0 3px 20px rgb(0 0 0 / 9%);
}

ion-header ion-button {
    text-transform: capitalize;
    color: #000;
}

ion-item:hover {
    --color: #fff;
}

.justify-center{
    justify-content: center;
}

.modal-wrapper:after {content: '';background: #d8d8d8;height: 7px;width: 30px;border-radius: 50px;position: absolute;left: 0;top: 10px;right: 0;margin: auto;}

ion-buttons ion-button {
    height: auto !important;
    --padding-top: 2px !important;
    --padding-bottom: 2px !important;
}

ion-header {
    background: #fff;
    ion-title {
        font-weight: normal;        
        padding: 0 92px;
        font-family: 'medium';
        color: #000;
    }
}

ion-footer ion-toolbar button {
    width: 100%;
    height: 52px;
    background: #000;
    border-radius: 50px;
    font-family: 'bold';
    font-size: 18px;
    font-weight: 800;
    color: #fff;
    margin-top: 20px;
    margin-bottom: 20px;
}

ion-footer ion-toolbar {
    --min-height: 90px;
}

ion-button{
    --background-activated:none;
}

ion-back-button.white-icon {
    background: url('assets/image/back-white.svg');
    background-repeat: no-repeat;
    width: 34px;
    height: 34px;
    background-size: cover;
}

ion-back-button.cross {
    background: url('assets/image/cross.svg');
    background-repeat: no-repeat;
    width: 16px;
    min-height: 16px !important;
    background-size: contain;
    margin: 0 10px;
}


ion-segment {
    background: transparent;
}

ion-segment ion-segment-button.segment-button-checked {
    --indicator-box-shadow: none;
    --indicator-color: #D83289;
    ion-label {color: #fff;}
}

ion-segment ion-segment-button {
    --background: #E8E8E8;
    --border-radius: 50px;
    --border-width: 0;
    min-width: auto;
    margin: 0 10px 0 0;
    min-height: 40px;
}

ion-segment ion-segment-button ion-label {font-family: 'bold';color: #000;font-weight: 800;}

.arrow{
    max-width: 5%;
  }